export default {
  user_service_url: `https://${window.location.host}/api`,
  // user_service_url: 'http://localhost:8080/api',
  qf_site_url: 'https://openapi-test.qfpay.com',
  qf_app_code: '099B38477369402A855105CDA3C8914D',
  qf_client_key: '303346A1241A4A26A1ED55088D711528',
  qf_pay_type: '800201',
  qf_pay_type_alipay: '801501',
  qf_pay_type_fps: '802001',
  qf_pay_type_alipay_tag: 'ALIPAYHK',
  qf_txcurrcd: 'HKD',
  qf_pay_type_creditCard: '805508',
};
